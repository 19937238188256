import { render, staticRenderFns } from "./FileRead.vue?vue&type=template&id=3905513b&scoped=true"
import script from "./FileRead.vue?vue&type=script&lang=js"
export * from "./FileRead.vue?vue&type=script&lang=js"
import style0 from "./FileRead.vue?vue&type=style&index=0&id=3905513b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3905513b",
  null
  
)

export default component.exports